import React from "react";
import { Container } from "react-bootstrap";
import { Title, SubTitle, Description, MainSection } from "../style";
const Cookie = ({ title }) => {
    return (
        <MainSection>
            <Container className="w-75">
                <Title>{title}</Title>
                <Description>
                    To make this site work properly, we sometimes place small
                    data files called cookies on your device. Most big websites
                    do this too.
                </Description>
                <SubTitle>What are cookies?</SubTitle>
                <Description>
                    A cookie is a small text file that a website saves on your
                    computer or mobile device when you visit the site. It
                    enables the website to remember your actions and preferences
                    (such as login, language, font size and other display
                    preferences) over a period of time, so you don’t have to
                    keep re-entering them whenever you come back to the site or
                    browse from one page to another.
                </Description>

                <SubTitle>How do we use cookies?</SubTitle>

                <Container className="pt-4 pl-5">
                    <Description>
                        -&nbsp; We use cookies to remember users' actions, to identify
                        the user, for online behavioural advertising)
                        <br />
                        -&nbsp; Cookies are essential for the website or a given
                        functionality to work or if they aim to enhance the
                        performance of the website
                        <br />
                        -&nbsp; the types of cookies used (e.g. session or permanent,
                        first or third-party)
                        <br />
                        -&nbsp; Woorg.com & its advertising partner (third party
                        website) controls/accesses the cookie-related
                        information.
                        <br />-&nbsp; Cookies will not be used for any purpose other
                        than the one stated
                    </Description>
                </Container>
                <SubTitle>
                    How long does Woorg retain Personal Information ?
                </SubTitle>
                <Description>
                    You can control and/or delete cookies as you wish - for
                    details, see aboutcookies.org. You can delete all cookies
                    that are already on your computer and you can set most
                    browsers to prevent them from being placed. If you do this,
                    however, you may have to manually adjust some preferences
                    every time you visit a site and some services and
                    functionalities may not work.
                </Description>
            </Container>
        </MainSection>
    );
};

export default Cookie;
